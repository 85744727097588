import React from 'react'

const About = () => (
  <section className="About">
    <h1>ABOUT</h1>
    <div className="bio">
      <div>
        The original sauce lah was born from a batch of super-spicy hybrid chili
        peppers grown in my parents backyard in the Gold Coast of Australia.
        Thata (my dad) threw all of those peppers into a blender, along with the
        flavors and ingredients he knew well from living, eating, and cooking in
        Southeast Asia.
      </div>
      <div>
        Growing up in Singapore, I was trained from a young age to eat well, and
        eat spicy. sauce lah reflects the melting pot of Southeast Asia that
        exists there – in it, you'll find tastes of Sri Lanka, China, Malaysia,
        and Indonesia – and of course, a decent level of spice!
      </div>
      <div>We hope you like it!</div>
      <div>~ Nat Uduwela</div>
    </div>
  </section>
)

export default About
